

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  justify-content: center;
  align-items: center;

  font-size: calc(10px + 2vmin);
  color: white;
}

.cursor {
  cursor: pointer;
}


.Map-Div {
  height: 90%;
  width: 90%;

}

.App-link {
  color: #61dafb;
}

.heart-pulsate {
  animation: pulsate 1.8s infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1); /* Scale up to create the pulsating effect */
  }
  60% {
    transform: scale(1.2); /* Scale up to create the pulsating effect */
  }
  70% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.1); /* Scale up to create the pulsating effect */
  }
  100% {
    transform: scale(1);
  }
}

.rounded-map-container {
  border-radius: 10px;
  overflow: hidden;
}